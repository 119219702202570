<template>
  <v-container class="container-page my-messages">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="hasThreads"
          color="accent"
          data-cy="createMessage"
          :to="{ name: 'MessagesNew' }"
          fixed
          bottom
          right
          dark
          fab
          v-on="on">
          <v-icon>{{ $root.icons.action.add }}</v-icon>
        </v-btn>
      </template>
      <span>Crie uma mensagem</span>
    </v-tooltip>

    <v-card class="mt-0 transparent">
      <loading
        v-if="loading > 0"
        class="absolute-center" />

      <template v-else>
        <v-card
          v-if="hasThreads"
          class="transparent elevation-0 card-pt-0">
          <v-card-title class="pt-0 pl-0">
            <h1 class="main-title">
              Mensagens
            </h1>
            <v-spacer />
            <v-flex
              xs12
              sm5>
              <v-text-field
                v-model="search"
                label="Buscar por mensagens"
                :append-icon="$root.icons.layout.search"
                single-line
                hide-details />
            </v-flex>
          </v-card-title>
          <v-data-table
            class="transparent table-sticky table-clicked table-messages"
            :headers="headers"
            :options.sync="pagination"
            :items="threads"
            :search="search"
            :footer-props="footerProps">
            <template
              v-slot:item="props"
              class="transparent">
              <tr
                tabindex="0"
                :class="{'not-read': !props.item.lastMessage.read }"
                @click="selectMessage(props.item.id)">
                <td>
                  <v-layout
                    align-center
                    row
                    spacer>
                    <v-flex
                      xs4
                      sm2
                      md2>
                      <template v-slot:activator="{ on }">
                        <v-avatar
                          size="36px"
                          v-on="on">
                          <user-avatar
                            :image="props.item.lastMessage.from.photoURL"
                            :email="props.item.lastMessage.from.email"
                            :alt="props.item.lastMessage.from.name" />
                        </v-avatar>
                      </template>
                    </v-flex>
                    <v-flex
                      sm5
                      md5
                      hidden-xs-only
                      offset-sm1>
                      <span
                        class="text"
                        v-html="props.item.lastMessage.from.name" />
                      <span
                        v-if="props.item.unreadMessages > 0"
                        class="grey--text">&nbsp;({{ props.item.unreadMessages }})</span>
                    </v-flex>
                    <v-flex
                      text-no-wrap
                      xs4
                      sm9
                      ellipsis>
                      <!--  <v-chip
                        color="green lighten-4"
                        label
                        small
                        class="ml-0"
                      >novo</v-chip> -->
                      <span
                        class="text"
                        v-html="props.item.subject" />
                    </v-flex>
                    <v-flex
                      class="grey--text pl-2"
                      ellipsis
                      hidden-sm-and-down>
                      <div
                        class="ql-editor"
                        v-html="props.item.lastMessage.content" />
                    </v-flex>
                  </v-layout>
                </td>
                <td class="text-right">
                  <span class="text"> {{ props.item.lastMessage.insertedAt }}</span>
                </td>
              </tr>
            </template>
            <template v-slot:pageText="props">
              Exibindo de {{ props.pageStart }} a {{ props.pageStop }} no total de {{ props.itemsLength }}
            </template>
            <empty-state
              slot="no-results"
              class="mt-2"
              size="75px"
              :icon="$root.icons.state.no_result">
              Sua busca por "{{ search }}" não possui resultados
            </empty-state>
          </v-data-table>
        </v-card>

        <template v-else>
          <EmptyState
            :icon="$root.icons.link.messages"
            class="absolute-center">
            Você não possui nenhuma mensagem até o momento<br>
            <v-btn
              class="mt-2"
              color="accent"
              :to="{ name: 'MessagesNew' }"
              dark
              depressed
              small>
              Criar mensagem
            </v-btn>
          </EmptyState>
        </template>
      </template>
    </v-card>

    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>
  </v-container>
</template>

<script>
  import UserAvatar from '@/components/ui/UserAvatar'
  import Loading from '@/components/ui/Loading'
  import EmptyState from '@/components/ui/EmptyState'

  export default {
    components: { UserAvatar, Loading, EmptyState },
    data () {
      return {
        footerProps: {
          rowsPerPageItems: [25, 50, 100],
          rowsPerPageText: 'Mostrar por página'
        },
        validReply: true,
        messageId: null,
        search: '',
        loading: 0,
        threads: [],
        headers: [
          { text: '', sortable: false, value: 'lastMessage.content' },
          // { text: 'Assunto', sortable: false, value: '' },
          { text: 'Data', sortable: true, value: 'insertedAt', align: 'right' }
        ],
        pagination: {
          sortBy: 'insertedAt',
          descending: true
        }
      }
    },
    // TODO Pegar lista de mensagens
    computed: {
      hasThreads () {
        return this.threads.length > 0
      }
    },
    methods: {
      selectMessage (id) {
        this.messageId = id
        this.$router.push({ name: 'MessageShow', params: { messageId: id } })
      }
    }
  }
</script>


<style lang="sass">
  .my-messages

    .not-read .text
      font-weight: bold

    // .table-messages tbody td
    //   height: 30px

    .ellipsis
      text-overflow: ellipsis
      width: 100%
      position: relative
      display: block
      position: relative
      overflow: hidden

      white-space: normal
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
      display: -webkit-box

    .v-list-item__sub-title
      white-space: normal

    .v-list-item
      height: auto

    .table-list-opened
      // border-bottom: none!important

      + .v-datatable__expand-row
        cursor: default

    .data-time
      float: right

    #scroll-target
      li
        padding: 5px 0

    .chat-messages .layout
      height: auto

</style>

